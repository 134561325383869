import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, navigate } from "gatsby";
import EventiGrid from "../../components/EventiGrid/EventiGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { isEventoGratuito, parseQuotaPartecipazione } from "../../utils/eventi";

export default function EventiFSCPage({ data }) {
  const eventi = data.allEventiJson.nodes;
  const professioni = data.allProfessioniJson.nodes;
  const discipline = data.allDisciplineJson.nodes;

  const [crediti, setCrediti] = useState("");
  const [costo, setCosto] = useState("");
  const [pagina, setPagina] = useState(1);

  const eventiFiltered = eventi
    .filter((evento) => {
      const [min, max] = crediti.split("-").map((value) => parseInt(value, 10));

      return (
        crediti === "" ||
        (min <= parseInt(evento.crediti, 10) &&
          (!max || parseInt(evento.crediti, 10) < max))
      );
    })
    .filter((evento) => {
      const [min, max] = costo.split("-").map((value) => parseInt(value, 10));
      const quotaPartecipazione = parseQuotaPartecipazione(
        evento.quota_partecipazione
      );

      return (
        costo === "" ||
        (costo === "gratuiti" &&
          isEventoGratuito(evento.quota_partecipazione)) ||
        (min <= quotaPartecipazione &&
          (isNaN(max) || quotaPartecipazione < max))
      );
    });
  const eventiCurrentPage = eventiFiltered.slice(0, 20 * pagina);

  return (
    <Layout pageTitle="Corsi ECM FSC">
      <section className="position-relative">
        <StaticImage
          src="../../images/eventi-fsc.jpg"
          className="img-header-home"
          alt="Corsi ECM FSC"
          title="Corsi ECM FSC"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-3">Corsi ECM FSC</h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Formazione Corsi ECM FSC, il catalogo on line di tutti i corsi ECM
              accreditati pFSCso Agenas.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div className="container-fluid" id="sezione-corsi">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM FSC in evidenza
            </h2>
          </div>
          <FormRicerca
            professioni={professioni}
            discipline={discipline}
            handleChangeCrediti={(e) => {
              setCrediti(e.currentTarget.value);
              setPagina(1);
            }}
            handleChangeCosto={(e) => {
              setCosto(e.currentTarget.value);
              setPagina(1);
            }}
          />
        </div>
      </div>
      <EventiGrid eventi={eventiCurrentPage} tipologia="FSC" />
      {eventiCurrentPage.length > 0 &&
        eventiCurrentPage.length < eventiFiltered.length && (
          <div className="container-fluid">
            <div className="row px-5 pt-3 pb-5">
              <div className="col-12 text-center">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setPagina((prev) => prev + 1)}
                >
                  Carica altri corsi
                </button>
              </div>
            </div>
          </div>
        )}
    </Layout>
  );
}

function FormRicerca({
  professioni,
  discipline,
  handleChangeCrediti,
  handleChangeCosto,
}) {
  return (
    <>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-professione">Professione</Form.Label>
          <Form.Select
            id="select-professione"
            onChange={(e) =>
              e.currentTarget.value && navigate(e.currentTarget.value)
            }
          >
            <option value="">Seleziona una professione</option>
            {professioni.map(({ descrizione, professionePath }) => (
              <option key={professionePath} value={professionePath}>
                {descrizione}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-disciplina">Disciplina</Form.Label>
          <Form.Select
            id="select-disciplina"
            onChange={(e) =>
              e.currentTarget.value && navigate(e.currentTarget.value)
            }
          >
            <option value="">Seleziona una disciplina</option>
            {discipline.map(({ disciplina, professione, disciplinaPath }) => (
              <option key={disciplinaPath} value={disciplinaPath}>
                {professione}: {disciplina}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-crediti">Crediti</Form.Label>
          <Form.Select id="select-crediti" onChange={handleChangeCrediti}>
            <option value="">Seleziona una fascia di crediti</option>
            <option value="0-10">Da 0 a 10 crediti</option>
            <option value="10-20">Da 10 a 20 crediti</option>
            <option value="20-30">Da 20 a 30 crediti</option>
            <option value="30-40">Da 30 a 40 crediti</option>
            <option value="40-">Oltre 40 crediti</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-12 col-md-4 col-lg-3">
        <Form.Group className="mb-4">
          <Form.Label htmlFor="select-costo">Costo</Form.Label>
          <Form.Select id="select-costo" onChange={handleChangeCosto}>
            <option value="">Seleziona una fascia di prezzo</option>
            <option value="gratuiti">Gratuiti</option>
            <option value="0-50">Da 0 a 50 euro</option>
            <option value="50-150">Da 50 a 150 euro</option>
            <option value="150-300">Da 150 a 300 euro</option>
            <option value="300-500">Da 300 a 500 euro</option>
            <option value="500-">Oltre 500 euro</option>
          </Form.Select>
        </Form.Group>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allProfessioniJson(sort: { fields: descrizione, order: ASC }) {
      nodes {
        descrizione
        professionePath: gatsbyPath(
          filePath: "/fsc/{ProfessioniJson.descrizione}"
        )
      }
    }
    allDisciplineJson(
      sort: { fields: [professione, disciplina], order: [ASC, ASC] }
    ) {
      nodes {
        disciplina
        professione
        disciplinaPath: gatsbyPath(
          filePath: "/fsc/{DisciplineJson.professione}/{DisciplineJson.disciplina}"
        )
      }
    }
    allEventiJson(
      filter: { tipo_evento: { eq: "FSC" } }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        quota_partecipazione
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
